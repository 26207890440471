.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
    animation-iteration-count: 1;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #61dafb;
  /*animation-direction: reverse;*/
}

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

@keyframes App-logo-spin {
    from {
      transform: rotateX(45deg);
    }
    to {
      transform: rotate(360deg);
    }
}
